const axios = require('axios').default;

export function getCategoriesFromApi (){
    const url = "https://apiannu.nessentielle.com/api/categories";

    return axios({
            method: 'get',
            url: url,
            mode: 'no-cors',
          })
            .then((response) => response.data)
            .catch((error) => console.error(error))
}

export function getSubcatsFromApi (category){
  const url = "https://apiannu.nessentielle.com/api/subcat/" + category;

  return axios({
          method: 'get',
          url: url,
          mode: 'no-cors',
        })
          .then((response) => response.data)
          .catch((error) => console.error(error))
}

export function getLocalisationsFromApi (){
  const url = "https://apiannu.nessentielle.com/api/localisations";

  return axios({
          method: 'get',
          url: url,
          mode: 'no-cors',
        })
          .then((response) => response.data)
          .catch((error) => console.error(error))
}

export function getBannerFromApi (){
    const url = "https://apiannu.nessentielle.com/api/banner";
    return axios({
        method: 'get',
        url: url,
        mode: 'no-cors',
      })
        .then((response) => response.data)
        .catch((error) => console.error(error))
}

export function getAdsFromApi(){
  const url = "https://apiannu.nessentielle.com/api/ads";
  return axios({
    method: 'get',
    url: url,
    mode: 'no-cors',
  })
    .then((response) => response.data)
    .catch((error) => console.error(error))
}

export function getPartnerFromApi(){
  const url = "https://apiannu.nessentielle.com/api/partner";
  return axios({
    method: 'get',
    url: url,
    mode: 'no-cors',
  })
    .then((response) => response.data)
    .catch((error) => console.error(error))
}

export function getSBHFromApi(){
  const url = "https://apiannu.nessentielle.com/api/sbh";
  return axios({
    method: 'get',
    url: url,
    mode: 'no-cors',
  })
    .then((response) => response.data)
    .catch((error) => console.error(error))
}

export function getAboutFromApi(){
  const url = "https://apiannu.nessentielle.com/api/about";
  return axios({
    method: 'get',
    url: url,
    mode: 'no-cors',
  })
    .then((response) => response.data)
    .catch((error) => console.error(error))
}

export function getSingleArticleFromApi (id){
  const url = "https://apiannu.nessentielle.com/api/article/"+id;
  return axios({
      method: 'get',
      url: url,
      mode: 'no-cors',
    })
      .then((response) => response.data[0])
      .catch((error) => console.error(error))
}

export function getSingleCompanyFromApi (id){
    const url = "https://apiannu.nessentielle.com/api/company/"+id;
    return axios({
        method: 'get',
        url: url,
        mode: 'no-cors',
      })
        .then((response) => response.data[0])
        .catch((error) => console.error(error))
}

export function getArticlesFromApi (page, categorie){
  const url = "https://apiannu.nessentielle.com/api/articles/"+page+"/"+categorie;
  return axios({
      method: 'get',
      url: url,
      mode: 'no-cors',
    })
      .then((response) => response.data)
      .catch((error) => console.error(error))
}

export function getCompaniesFromApi (page, category, keyword, subcat, localisation){
    const url = "https://apiannu.nessentielle.com/api/companies/"+page+"/"+category+"/"+keyword+"/"+subcat+"/"+localisation;
    return axios({
        method: 'get',
        url: url,
        mode: 'no-cors',
      })
        .then((response) => response.data)
        .catch((error) => console.error(error))
}

export function getCdcFromApi (categorie){
  const url = "https://apiannu.nessentielle.com/api/cdc/"+categorie;
  return axios({
      method: 'get',
      url: url,
      mode: 'no-cors',
    })
      .then((response) => response.data)
      .catch((error) => console.error(error))
}