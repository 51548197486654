import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom"
import Fade from 'react-reveal/Fade'
import { getCompaniesFromApi, getCategoriesFromApi, getSubcatsFromApi, getLocalisationsFromApi, getSingleCompanyFromApi, getCdcFromApi } from '../Service/API'
import Map from '../Components/map'
import LogoSmall from '../Components/LogoSmall'
import styled from 'styled-components'
import { IoCaretBack, IoCaretForward } from 'react-icons/io5'

const Container = styled.div`
    padding-left: 0;
    padding-right: 0;
    
    @media (max-width: 600px){
        padding-left: 20px;
        padding-right: 20px;
    }
`

function Result(props) {
    const {initCategorie} = useParams()
    const [categories, setCategories] = useState([])
    const [subcats, setSubcats] = useState([])
    const [localisations, setLocalisations] = useState([])
    const [categorie, setCategorie] = useState(initCategorie)
    const [subcat, setSubcat] = useState('all')
    const [loc, setLoc] = useState('all')
    const [input, setInput] = useState('all')
    const [page, setPage] = useState(0)
    const [maxPage, setMaxPage] = useState(0)
    const [data, setData] = useState([])
    const [company, setCompany] = useState([])
    const [cdc, setCdc] = useState(undefined)
    const [dt, setDt] = useState([])

    useEffect(() => {
        window.scrollTo(0,0)
        window.history.replaceState(null, categorie, "/results/"+categorie)
        getCategoriesFromApi().then((data)=>setCategories(data))
        getSubcatsFromApi(categorie).then((data)=>setSubcats(data))
        getLocalisationsFromApi().then((data)=>setLocalisations(data))
        if (categorie !== "all" & categorie !== undefined){
            getCdcFromApi(categorie).then((data)=>setCdc(data))
        }else{
            setCdc([])
        }
        getCompaniesFromApi(0, categorie, 'all', 'all', 'all').then((data)=>{
            if(data !== undefined){
                setData(data.result)
                setMaxPage(data.totalPages)
            }
        })
    }, []);

    const updateResult = (city, categorie, subcat, input, page) => {
        setLoc(city)
        setCategorie(categorie)
        setSubcat(subcat)
        setInput(input)
        setPage(page)
        window.history.replaceState(null, categorie, "/results/"+categorie)
        if(categorie === "all"){
            setSubcats([])
        }else{
            getSubcatsFromApi(categorie).then((data)=>setSubcats(data))
        }
        getCompaniesFromApi(page, categorie, input, subcat, city).then((data)=>{
            if(data !== undefined){
                setData(data.result)
                setMaxPage(data.totalPages)
            }
        })
        if (categorie !== "all" & categorie !== undefined){
            getCdcFromApi(categorie).then((data)=>setCdc(data))
        }
    }

    const loadCompanyData = (id) => {
        getSingleCompanyFromApi(id).then((data)=>{
            setCompany(data)
            var dt_update = [
                {name: "Secteur d'activité", val: data.secteur},
                {name: "Tarif à partir de", val: data.price},
                {name: "Diplome et/ou certificat", val: data.certification},
                {name: "Prix et/ou Distinction", val: data.award},
                {name: "Adresse", val: data.adresse},
                {name: "Commune", val: data.commune},
                {name: "Province", val: data.province},
                {name: "Tél", val: data.contact},
                {name: "E-Mail", val: data.email},
                {name: "Website", val: data.link},
                {name: "Facebook", val: data.facebook},
                {name: "Instagram", val: data.instagram},
                {name: "Twitter", val: data.twitter}
            ]

            setDt(dt_update)
        })
    }

    // var handleScroll = (e) => {
    //     console.log("EndScroll")
    //     const bottom = (e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight;
    //     if (bottom) {
    //         updateResult(loc, categorie, subcat, input, page+1)
    //         setPage(page+1)
    //     }
    // }

    return(
        <div style={{paddingTop: "8rem"}}>
        <Container>
            <Fade bottom>

                {/* Search bar */}

                <section>
                    <div className="container">
                        <div className='d-none d-md-block'>
                            <div style={{padding: 0, marginBlock: "1.5rem", borderRadius: "10rem", backgroundColor: "rgb(235, 235, 235)"}}>
                                <div className="input-group input-group-lg" style={{padding: "1rem", width: "100%"}} type="text">
                                    <input 
                                        className="form-control"
                                        placeholder="Mot-clé"
                                        style={{borderRadius: 0, borderTopRightRadius: "10rem", borderRadius: "10rem", marginRight: "0.75rem"}}
                                        type="text"
                                        onChange={(input)=>{
                                                if((input.target.value).replace(" ", "") === ""){
                                                    updateResult(loc, categorie, subcat, 'all', 0)
                                                }else{
                                                    updateResult(loc, categorie, subcat, input.target.value, 0)
                                                }
                                            }
                                        }
                                    />
                                    <div 
                                        className="btn"
                                        style={{color: "black", backgroundColor: "#c89ba2", borderRadius: "5rem", paddingInline: "2rem", display: "flex", alignItems: "center", justifyContent: "center"}}
                                        onClick={()=>{
                                            updateResult(loc, categorie, subcat, input, 0)
                                        }}
                                        >
                                        Rechercher
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{marginLeft: "0.25rem", marginRight: "0.25rem"}}>
                                <div className="input-group mb-3 col-12 col-lg-6">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text" for="region">REGION</label>
                                    </div>
                                    <select 
                                        className="custom-select" id="region"
                                        value={loc}
                                        onChange={(v)=>{
                                            updateResult(v.target.value, categorie, subcat, input, 0)
                                        }}
                                    >
                                        <option value='all'>Toutes</option>
                                        {
                                            localisations.map((item)=>{
                                                return(
                                                    <option
                                                        value={item.city}
                                                    >
                                                        {item.city}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="input-group mb-3 col-12 col-lg-6">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text" for="categorie">CATEGORIE</label>
                                    </div>
                                    <select 
                                        className="custom-select" id="categorie"
                                        value={categorie}
                                        onChange={(v)=>{
                                            updateResult(loc, v.target.value, 'all', input, 0)
                                        }}
                                    >
                                        <option value='all'>Toutes</option>
                                        {
                                            categories.map((item)=>{
                                                return(
                                                    <option
                                                        value={item.name}
                                                    >
                                                        {item.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='d-block d-md-none d-sm-none'>
                            <div style={{width: "100%"}}>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text" for="regionM">REGION</label>
                                    </div>
                                    <select 
                                        className="custom-select" id="regionM"
                                        onChange={(v)=>{
                                            updateResult(v.target.value, categorie, subcat, input, 0)
                                        }}
                                    >
                                        <option value='all'>Toutes</option>
                                        {
                                            localisations.map((item)=>{
                                                return(
                                                    <option
                                                        value={item.city}
                                                    >
                                                        {item.city}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text" for="categorieM">CATEGORIE</label>
                                    </div>
                                    <select 
                                        className="custom-select" id="categorieM"
                                        onChange={(v)=>{
                                            updateResult(loc, v.target.value, 'all', input, 0)
                                        }}
                                    >
                                        <option value='all'>Toutes</option>
                                        {()=>{
                                            if(categories !== undefined && categories !== []){
                                                categories.map((item)=>{
                                                    return(
                                                        <option
                                                            value={item.name}
                                                        >
                                                            {item.name}
                                                        </option>
                                                    )
                                                })
                                            }
                                        }
                                        }
                                    </select>
                                </div>
                                <br />
                                <input 
                                    className="form-control"
                                    placeholder="Mot-clé"
                                    style={{borderRadius: 0}}
                                    type="text"
                                    onChange={(input)=>{
                                            if((input.target.value).replace(" ", "") === ""){
                                                updateResult(loc, categorie, subcat, 'all', 0)
                                            }else{
                                                updateResult(loc, categorie, subcat, input.target.value, 0)
                                            }
                                        }
                                    }
                                />
                                <div 
                                    className="btn"
                                    style={{color: "black", backgroundColor: "#c89ba2", paddingInline: "2rem", display: "flex", alignItems: "center", justifyContent: "center"}}
                                    onClick={()=>{
                                        updateResult(loc, categorie, subcat, input, 0)
                                    }}
                                    >
                                    Rechercher
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section style={{marginBottom: "5rem"}}>
                    <div className="container">
                        <br></br>

                        {/* Subcategories */}

                        {
                            (categorie !== 'all' && subcats !== [])?(
                                <div style={{overflowX: "scroll", display: "flex", flexWrap: "nowrap", marginBottom: "1.5rem"}}>
                                    <div 
                                        className='btn btn-sm'
                                        style={{backgroundColor: ("all" === subcat)?("#c89ba2"):("#e5c8cc"), padding: "1rem", borderRadius: "4rem", marginRight: "1rem"}}
                                        onClick={()=>{
                                                updateResult(loc, categorie, 'all', input, 0)
                                            }
                                        }
                                    >
                                        <span style={{color: "white"}}>Toutes</span>
                                    </div>
                                    {
                                        subcats.map((item)=>{
                                            return(
                                                <div 
                                                    className='btn btn-sm'
                                                    style={{backgroundColor: (item.subcategory === subcat)?("#c89ba2"):("#e5c8cc"), padding: "1rem", borderRadius: "4rem", marginRight: "1rem"}}
                                                    onClick={()=>{
                                                        updateResult(loc, categorie, item.subcategory, input, 0)
                                                    }
                                                }
                                                >
                                                    <span style={{color: "white"}}>{item.subcategory}</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            ):(
                                <></>
                            )
                        }
                        <br></br>

                        {/* Coup de coeur */}

                        {
                            (cdc === [] || cdc === undefined)?(
                                <>
                                </>
                            ):(
                                <>
                                    <br />
                                    <h3>
                                        COUP DE COEUR
                                    </h3>
                                    <br></br>
                                    <div 
                                        style={{alignItems: "center", justifyContent: "space-evenly", display: "flex", flexWrap: "wrap"}}
                                    >
                                        {
                                            cdc.map((item, id) => {
                                                if(item.type === 1){
                                                    return(
                                                        <Fade bottom>
                                                            <div 
                                                                style={{width: "15rem", height: "15rem", backgroundImage: `url(${item.thumbnail})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", borderRadius: "25px", marginBottom: "4rem", display: "flex", overflow: "hidden", justifyContent: "center", alignItems: "flex-end"}}
                                                                onClick={()=>loadCompanyData(item.id)}
                                                                data-toggle="modal"
                                                                data-target="#modal"
                                                            >
                                                                <div style={{width: "100%", height: "fit-content", backgroundColor: "rgba(240, 157, 233, 0.467)", display: "flex", alignItems: "center", justifyContent: "center", padding: "0.5rem"}}>
                                                                    <h4>
                                                                        {item.denomination_sociale}
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </Fade>
                                                    )
                                                }else if(item.type === 2){
                                                    return(
                                                        <Fade bottom>
                                                            <Link 
                                                                to={"/result/"+item.id}
                                                                style={{width: "15rem", height: "15rem", backgroundImage: "url("+item.thumbnail+")", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", borderRadius: "25px", marginBottom: "4rem", display: "flex", overflow: "hidden", justifyContent: "center", alignItems: "flex-end"}}
                                                            >
                                                                <div style={{width: "100%", height: "fit-content", backgroundColor: "rgba(240, 157, 233, 0.467)", display: "flex", alignItems: "center", justifyContent: "center", padding: "0.5rem"}}>
                                                                    <h4>
                                                                        {item.denomination_sociale}
                                                                    </h4>
                                                                </div>
                                                            </Link>
                                                        </Fade>
                                                    )
                                                }else if(item.type === 3){
                                                    return(
                                                        <Fade bottom>
                                                            <Link 
                                                                to={"/partenaire/"+item.id}
                                                                style={{width: "15rem", height: "15rem", backgroundImage: "url("+item.thumbnail+")", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", borderRadius: "25px", marginBottom: "4rem", display: "flex", overflow: "hidden", justifyContent: "center", alignItems: "flex-end"}}
                                                            >
                                                                <div style={{width: "100%", height: "fit-content", backgroundColor: "rgba(240, 157, 233, 0.467)", display: "flex", alignItems: "center", justifyContent: "center", padding: "0.5rem"}}>
                                                                    <h4>
                                                                        {item.denomination_sociale}
                                                                    </h4>
                                                                </div>
                                                            </Link>
                                                        </Fade>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                </>
                            )
                        }

                        {/* Search Result */}

                        {
                            (data !== [])?(
                                <>
                                    <h3>
                                        RESULTATS | {(categorie === "all")?("TOUTES CATEGORIES CONFONDUES"):(categorie)}
                                    </h3>
                                    <br></br>
                                    <div
                                        style={{alignItems: "center", justifyContent: "space-evenly", display: "flex", flexWrap: "wrap"}}
                                    >
                                        {
                                            data.map((item, id) => {
                                                if(item.type === 1){
                                                    return(
                                                        <Fade bottom>
                                                            <div 
                                                                style={{width: "15rem", height: "15rem", backgroundImage: `url(${item.thumbnail})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", borderRadius: "25px", marginBottom: "4rem", display: "flex", overflow: "hidden", justifyContent: "center", alignItems: "flex-end"}}
                                                                onClick={()=>loadCompanyData(item.id)}
                                                                data-toggle="modal"
                                                                data-target="#modal"
                                                            >
                                                                <div style={{width: "100%", height: "fit-content", backgroundColor: "rgba(240, 157, 233, 0.467)", display: "flex", alignItems: "center", justifyContent: "center", padding: "0.5rem"}}>
                                                                    <h4>
                                                                        {item.denomination_sociale}
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </Fade>
                                                    )
                                                }else if(item.type === 2){
                                                    return(
                                                        <Fade bottom>
                                                            <Link 
                                                                to={"/result/"+item.id}
                                                                style={{width: "15rem", height: "15rem", backgroundImage: "url("+item.thumbnail+")", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", borderRadius: "25px", marginBottom: "4rem", display: "flex", overflow: "hidden", justifyContent: "center", alignItems: "flex-end"}}
                                                            >
                                                                <div style={{width: "100%", height: "fit-content", backgroundColor: "rgba(240, 157, 233, 0.467)", display: "flex", alignItems: "center", justifyContent: "center", padding: "0.5rem"}}>
                                                                    <h4>
                                                                        {item.denomination_sociale}
                                                                    </h4>
                                                                </div>
                                                            </Link>
                                                        </Fade>
                                                    )
                                                }else if(item.type === 3){
                                                    return(
                                                        <Fade bottom>
                                                            <Link 
                                                                to={"/partenaire/"+item.id}
                                                                style={{width: "15rem", height: "15rem", backgroundImage: "url("+item.thumbnail+")", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", borderRadius: "25px", marginBottom: "4rem", display: "flex", overflow: "hidden", justifyContent: "center", alignItems: "flex-end"}}
                                                            >
                                                                <div style={{width: "100%", height: "fit-content", backgroundColor: "rgba(240, 157, 233, 0.467)", display: "flex", alignItems: "center", justifyContent: "center", padding: "0.5rem"}}>
                                                                    <h4>
                                                                        {item.denomination_sociale}
                                                                    </h4>
                                                                </div>
                                                            </Link>
                                                        </Fade>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                    <div className="container d-flex justify-content-end">
                                        <div className="input-group input-group-lg justify-content-end">
                                            <button 
                                                className="btn"
                                                style={{backgroundColor: "rgba(240, 157, 233, 1)"}}
                                                onClick={()=>{
                                                    if(page > 0){
                                                        updateResult(loc, categorie, subcat, input, page-1)
                                                    }
                                                }}
                                            >
                                                <IoCaretBack
                                                    size={25}
                                                    color="#fff"
                                                />
                                            </button>
                                            <div className="input-group-text" style={{margin: "0 0.5rem 0 0.5rem"}}>
                                                {page+1+"/"+maxPage}
                                            </div>
                                            <button 
                                                className="btn"
                                                style={{backgroundColor: "rgba(240, 157, 233, 1)"}}
                                                onClick={()=>{
                                                    if(page < data.totalPages-1){
                                                        updateResult(loc, categorie, subcat, input, page+1)
                                                    }
                                                }}
                                            >
                                                <IoCaretForward
                                                    size={25}
                                                    color="#fff"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ):(
                                <></>
                            )
                        }
                    </div>
                </section>
            </Fade>

            {/* Result Modal */}

                <section>
                    <div className="modal fade" tabindex="-1" role="dialog" id="modal" aria-labelledby="modal" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">
                                            &times;
                                        </span>
                                    </button>
                                </div>
                                {
                                    (company === [] || company === undefined)?(
                                        <></>
                                    ):(
                                        <div className="modal-body">
                                            <div style={{display: "flex", flexWrap: "nowrap", alignItems: "center"}}>
                                                <img className="rounded" src={company.logo} style={{height: "150px", width: "auto"}} alt="" srcset="" />
                                                <div style={{paddingLeft: "2rem"}}>
                                                    <h1>{company.denomination_sociale}</h1>
                                                    <h3>{company.metier} | {company.specialisation}</h3>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            {
                                                (company.image)?(

                                                    <div id="carousel" className="carousel slide" data-ride="carousel">
                                                        <ol className="carousel-indicators">
                                                            {
                                                                JSON.parse(company.image).map((item, id) => {
                                                                    return(
                                                                        <li data-target="#carousel" data-slide-to={id} className={(id===0)?("active"):("")}></li>
                                                                    )
                                                                })
                                                            }
                                                        </ol>
                                                        <div className="carousel-inner">
                                                            {
                                                                JSON.parse(company.image).map((item)=>{
                                                                    return(
                                                                        <div className="carousel-item active">
                                                                            <img src={"https://apiannu.nessentielle.com/storage/app/public/"+item} alt={item.alt} className="d-clock w-100" />
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        <a href="#carousel" role="button" data-slide="prev" className="carousel-control-prev">
                                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                        </a>
                                                        <a href="#carousel" role="button" data-slide="next" className="carousel-control-next">
                                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                        </a>
                                                    </div>
                                                ):(
                                                    <></>
                                                )
                                            }
                                            <br></br>
                                            <div className="d-flex nowrap">
                                                <div style={{width: '50%', borderRadius: 20, overflow:"hidden"}}>
                                                    {
                                                        (company.streetmap)?(
                                                            <Map
                                                                location={company.streetmap}
                                                            />
                                                        ):(
                                                            <></>
                                                        )
                                                    }
                                                </div>
                                                <div className="pl-3">
                                                    <p>
                                                        {
                                                                dt.map((item, index)=>{
                                                                    if(item.val !== undefined && item.val !== [] && item.val !== null && (item.val).replace(" ", "") !== ""){
                                                                        return(
                                                                            <>
                                                                                <b>
                                                                                    {item.name}
                                                                                </b>
                                                                                <br></br>| {item.val}
                                                                                <br></br>
                                                                            </>
                                                                        )
                                                                    }
                                                                })
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <br></br>
                                            <div>
                                                <p style={{textAlign: "justify", textIndent: "2rem"}}>
                                                    {company.description}
                                                </p>
                                            </div>
                                        </div>
                                    )
                                }
                                <div className="modal-footer">
                                    <button className="btn btn-secondary" type="button" data-dismiss="modal">
                                        Fermer
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Container>
            <LogoSmall/>
        </div>
    )
}

export default Result;