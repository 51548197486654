import React from 'react'
import { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import Fade from 'react-reveal/Fade'
import LogoSmall from '../Components/LogoSmall'
import { getPartnerFromApi } from "../Service/API"
import styled from 'styled-components'

const Container = styled.div`
    padding-left: 0;
    padding-right: 0;
    
    @media (max-width: 600px){
        padding-left: 20px;
        padding-right: 20px;
    }
`

function Category(props) {
    const [partners, setPartners] = useState([]);

    useEffect(() => {
        window.scrollTo(0,0)
        getPartnerFromApi().then((data)=>setPartners(data))
    }, []);


    return(
        <div style={{paddingTop: "6rem"}}>
            <Container>
                <section style={{marginTop: "1rem", marginBottom: "5rem"}}>
                    <div className="container">
                        <Fade bottom>
                            <h1 style={{textAlign: "center"}}>
                                    PARTENAIRES
                            </h1>
                        </Fade>
                        <br></br>
                        <div style={{alignItems: "center", justifyContent: "space-evenly", display: "flex", flexWrap: "wrap"}}>
                        {
                            (partners.length > 0)?(
                                <Fade bottom>
                                    {
                                        partners.map(item=>{
                                            if(item.pageId !== undefined && item.pageId !== []){
                                                if(item.link !== undefined && item.link !== []){
                                                    return(
                                                        <div 
                                                            style={{width: "15rem", height: "15rem", backgroundImage: "url("+item.image+")", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", borderRadius: "25px", marginBottom: "4rem", display: "flex", overflow: "hidden", justifyContent: "center", alignItems: "flexEnd"}}
                                                        >
                                                            <div style={{width: "100%", backgroundColor: "rgba(240, 157, 233, 0.467)", display: "flex", alignItems: "center", justifyContent: "center", padding: "0.5rem"}}>
                                                                <a href={item.link} style={{paddingInline: "2rem", textAlign: "center", color:"white"}}>
                                                                    <h3>{item.name}</h3>
                                                                </a>
                                                                <img src={item.logo} style={{position:"absolute", height: 60, width: 60, bottom: 20, right: 20}} alt={item.name} />
                                                            </div>
                                                        </div>
                                                    )
                                                }else{
                                                    return(
                                                        <div 
                                                            style={{width: "15rem", height: "15rem", backgroundImage: "url("+item.image+")", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", borderRadius: "25px", marginBottom: "4rem", display: "flex", overflow: "hidden", justifyContent: "center", alignItems: "flexEnd"}}
                                                        >
                                                            <div style={{width: "100%", backgroundColor: "rgba(240, 157, 233, 0.467)", display: "flex", alignItems: "center", justifyContent: "center", padding: "0.5rem"}}>
                                                                <Link to={"/partenaire/"+item.pageId} style={{paddingInline: "2rem", textAlign: "center", color:"white"}}>
                                                                    <h3>{item.name}</h3>
                                                                </Link>
                                                                <img src={item.logo} style={{position:"absolute", height: 60, width: 60, bottom: 20, right: 20}} alt={item.name} />
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            }else{
                                                if(item.link !== undefined && item.link !== []){
                                                    return(
                                                        <div 
                                                            style={{width: "15rem", height: "15rem", backgroundImage: "url("+item.image+")", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", borderRadius: "25px", marginBottom: "4rem", display: "flex", overflow: "hidden", justifyContent: "center", alignItems: "flexEnd"}}
                                                        >
                                                            <div style={{width: "100%", backgroundColor: "rgba(240, 157, 233, 0.467)", display: "flex", alignItems: "center", justifyContent: "center", padding: "0.5rem"}}>
                                                                <a href={item.link} style={{paddingInline: "2rem", textAlign: "center", color:"white"}}>
                                                                    <h3>{item.name}</h3>
                                                                </a>
                                                                <img src={item.logo} style={{position:"absolute", height: 60, width: 60, bottom: 20, right: 20}} alt={item.name} />
                                                            </div>
                                                        </div>
                                                    )
                                                }else{
                                                    return(
                                                        <div 
                                                            style={{width: "15rem", height: "15rem", backgroundImage: "url("+item.image+")", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", borderRadius: "25px", marginBottom: "4rem", display: "flex", overflow: "hidden", justifyContent: "center", alignItems: "flexEnd"}}
                                                        >
                                                            <div style={{width: "100%", backgroundColor: "rgba(240, 157, 233, 0.467)", display: "flex", alignItems: "center", justifyContent: "center", padding: "0.5rem"}}>
                                                                    <h3>{item.name}</h3>
                                                                <img src={item.logo} style={{position:"absolute", height: 60, width: 60, bottom: 20, right: 20}} alt={item.name} />
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            }
                                        })
                                    }
                                </Fade>
                            ):(
                                <></>
                            )
                        }
                        </div>
                    </div>
                </section>
            </Container>
            <LogoSmall/>
        </div>
    )
}

export default Category;