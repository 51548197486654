import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { IoHome, IoApps, IoDiamond, IoLibrary, IoChatbubbleEllipses, IoInformationCircle } from 'react-icons/io5';
import '../assets/customCss/nav.css'

const color = {
    color1: "#ffffff",
    color2: "#c89ba2",
}

const NavLink1 = styled(NavLink)`

`
const Int1 = styled.p`
    filter: opacity(0);
    transition: all 0.5s ease;

    ${NavLink1}:hover &{
        filter: opacity(1);
    }
`

const NavLink2 = styled(NavLink)`

`
const Int2 = styled.p`
    filter: opacity(0);
    transition: all 0.5s ease;

    ${NavLink2}:hover &{
        filter: opacity(1);
    }
`

const NavLink3 = styled(NavLink)`

`
const Int3 = styled.p`
    filter: opacity(0);
    transition: all 0.5s ease;

    ${NavLink3}:hover &{
        filter: opacity(1);
    }
`

const NavLink4 = styled(NavLink)`

`
const Int4 = styled.p`
    filter: opacity(0);
    transition: all 0.5s ease;

    ${NavLink4}:hover &{
        filter: opacity(1);
    }
`

const NavLink5 = styled(NavLink)`

`
const Int5 = styled.p`
    filter: opacity(0);
    transition: all 0.5s ease;

    ${NavLink5}:hover &{
        filter: opacity(1);
    }
`

const NavLink6 = styled(NavLink)`

`
const Int6 = styled.p`
    filter: opacity(0);
    transition: all 0.5s ease;

    ${NavLink6}:hover &{
        filter: opacity(1);
    }
`

const Icons = styled.div`
    background-color: #c89ba2;
    border-radius: 28px;
    padding: 7px;
    display: flex;
    flex-direction: column;
    align-items: end;
    position: fixed;
    bottom: 2rem;
    right: 1.5rem;
    z-index:3;
    &>*:not(:last-child){
        margin: 0.4rem 0;
    }
`


const Navbar = (props) => {
    return (
        <Icons>
            <NavLink1 style={{color:'inherit', display: 'flex', flexDirection: 'row', alignItems: 'center'}}  to="/">
                <Int1 
                    className="bg-white-round"
                    style={{fontSize:13, color: "#000000", position: "absolute", transform: "translate(calc(-100% - 0.5rem), 50%)"}}
                >
                    Accueil
                </Int1>
                <div
                    style={{
                        paddingBottom: 0
                    }}
                >
                    <IoHome
                        size={25}
                        color={ color.color1 }
                    />
                </div>
            </NavLink1>
                <NavLink4 style={{color:'inherit', display: 'flex', flexDirection: 'row', alignItems: 'center'}}  to="/category">
                    <Int4 
                        className="bg-white-round"
                        style={{fontSize:13, color: "#000000", position: "absolute", transform: "translate(calc(-100% - 0.5rem), 50%)"}}
                    >
                        Catégories
                    </Int4>
                    <div
                        style={{
                            paddingBottom: 0
                        }}
                    >
                        <IoApps
                            size={25}
                            color={ color.color1 }
                        />
                    </div>
                </NavLink4>
                <NavLink5 style={{color:'inherit', display: 'flex', flexDirection: 'row', alignItems: 'center'}}  to="/articles">
                    <Int5 
                        className="bg-white-round"
                        style={{fontSize:13, color: "#000000", position: "absolute", transform: "translate(calc(-100% - 0.5rem), 50%)"}}
                    >
                        Blog
                    </Int5>
                    <div
                        style={{
                            paddingBottom: 0
                        }}
                    >
                        <IoLibrary
                            size={25}
                            color={ color.color1 }
                        />
                    </div>
                </NavLink5>
                <NavLink3 style={{color:'inherit', display: 'flex', flexDirection: 'row', alignItems: 'center'}}  to="/partners">
                    <Int3 
                        className="bg-white-round"
                        style={{fontSize:13, color: "#000000", position: "absolute", transform: "translate(calc(-100% - 0.5rem), 50%)"}}
                    >
                        Partenaires
                    </Int3>
                    <div
                        style={{
                            paddingBottom: 0
                        }}
                    >
                        <IoDiamond
                            size={25}
                            color={ color.color1 }
                        />
                    </div>
                </NavLink3>
                <NavLink2 style={{color:'inherit', display: 'flex', flexDirection: 'row', alignItems: 'center'}}  to="/about">
                    <Int2 
                        className="bg-white-round"
                        style={{fontSize:13, color: "#000000", position: "absolute", transform: "translate(calc(-100% - 0.5rem), 50%)"}}
                    >
                        A_propos
                    </Int2>
                    <div
                        style={{
                            paddingBottom: 0
                        }}
                    >
                        <IoInformationCircle
                            size={25}
                            color={ color.color1 }
                        />
                    </div>
                </NavLink2>
                <NavLink6 style={{color:'inherit', display: 'flex', flexDirection: 'row', alignItems: 'center'}}  target="_blank" to={{pathname:"mailto:contact@annuaireenchante.mg"}}>
                    <Int6 
                        className="bg-white-round"
                        style={{fontSize:13, color: "#000000", position: "absolute", transform: "translate(calc(-100% - 0.5rem), 50%)"}}
                    >
                        Contact
                    </Int6>
                    <div
                        style={{
                            paddingBottom: 5
                        }}
                    >
                        <IoChatbubbleEllipses
                            size={25}
                            color={ color.color1 }
                        />
                    </div>
                </NavLink6>
        </Icons>
    )
}

export default Navbar
