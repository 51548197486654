import React from 'react'
import { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import { getArticlesFromApi, getCategoriesFromApi } from "../Service/API"
import Fade from 'react-reveal/Fade'
import LogoSmall from '../Components/LogoSmall'
import styled from 'styled-components'

const Container = styled.div`
    padding-left: 0;
    padding-right: 0;
    
    @media (max-width: 600px){
        padding-left: 20px;
        padding-right: 20px;
    }
`

function Article(props) {

    const [page, setPage] = useState(0)
    const [categories, setCategories] = useState([])
    const [categorie, setCategorie] = useState('all')
    const [maxPage, setMaxPage] = useState(0)
    const [data, setData] = useState(undefined)

    useEffect(() => {
        window.scrollTo(0,0)
        getCategoriesFromApi().then((data)=>setCategories(data))
        getArticlesFromApi(0, 'all').then((data)=>{
            if(data !== undefined){
                setData(data.result)
                setMaxPage(data.totalPage)
            }
        })
    }, []);

    const updateResult = (page, categorie) => {
        setCategorie(categorie)
        getArticlesFromApi(page, categorie).then((data)=>{
            if(data !== undefined){
                setData(data.result)
                setMaxPage(data.totalPage)
            }
        })
    }

    var handleScroll = (e) => {
        const bottom = (e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight;
        if (bottom) {
            updateResult(page+1, categorie)
            setPage(page+1)
        }
    }

    console.log({categories: categories})

    return(
        <div style={{paddingTop: "6rem"}}>
            <Container>
                <section style={{marginTop: "1rem", marginBottom: "5rem"}}>
                    <div className="container">
                        <Fade bottom>
                            <h1 style={{textAlign: "center"}} >
                                ARTICLES
                            </h1>
                        </Fade>
                        <h3>
                            Articles et conseils sur:
                        </h3>
                        <div style={{overflowX: "scroll", display: "flex", flexWrap: "nowrap", marginBottom: "1.5rem"}}>
                            <div 
                                className='btn btn-sm'
                                style={{backgroundColor: ("all" === categorie)?("#c89ba2"):("#e5c8cc"), padding: "1rem", borderRadius: "4rem", marginRight: "1rem"}}
                                onClick={()=>{
                                        updateResult(page, "all")
                                    }
                                }
                            >
                                <span style={{color: "white"}}>Tout</span>
                            </div>
                            {
                                categories.map((item)=>{
                                    return(
                                        <div 
                                            className='btn btn-sm'
                                            style={{backgroundColor: (item.name === categorie)?("#c89ba2"):("#e5c8cc"), padding: "1rem", borderRadius: "4rem", marginRight: "1rem"}}
                                            onClick={()=>{
                                                updateResult(page, item.name)
                                            }
                                        }
                                        >
                                            <span style={{color: "white"}}>{(item.name).replaceAll(' ', '_')}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            (data !== undefined)?(
                                <Fade bottom>
                                    <br></br>
                                    <br></br>
                                    <div 
                                        style={{alignItems: "center", justifyContent: "space-evenly", display: "flex", flexWrap: "wrap" }}
                                        onScroll={()=>{
                                            if(page<maxPage){
                                                handleScroll()
                                            }
                                        }}
                                    >
                                        {
                                            data.map((item)=>{
                                                return(
                                                    <div style={{width: "15rem", height: "15rem", backgroundImage: `url(${item.image})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", borderRadius: "25px", marginBottom: "4rem", display: "flex", overflow: "hidden", justifyContent: "center", alignItems: "flex-end"}}
                                                        data-toggle="modal" data-target="#modal">
                                                        <div style={{width: "100%", height: "fit-content", backgroundColor: "rgba(240, 157, 233, 0.467)", display: "flex", alignItems: "center", justifyContent: "center", padding: "0.5rem"}}>
                                                            <Link to={"/article/"+item.id}>
                                                                <h4 style={{paddingInline: 20}} >{item.title}</h4>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Fade>
                            ):(
                                <></>
                            )
                        }
                    </div>
                </section>
            </Container>
            <LogoSmall/>
        </div>
    )
}

export default Article;