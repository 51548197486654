import React from 'react';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

function Map(props) {
    const position = [props.location.latitude, props.location.longitude]
    console.log(position)

    const icon = L.icon({ iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png" });

    return (
        <div style={{height: "25rem"}}>
            <MapContainer 
                style={{height: "25rem"}}
                center={position}
                zoom={17}
                scrollWheelZoom={true}
            >
                <TileLayer
                    //attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                    icon={icon}
                    position={position}
                >
                    {/* <Popup>
                        {props.title}
                    </Popup> */}
                </Marker>
            </MapContainer>
        </div>
    )
}

export default Map;