import React from 'react'
import { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import { getCategoriesFromApi } from "../Service/API"
import Fade from 'react-reveal/Fade'
import Icon from '../Annuaire.svg'
import LogoSmall from '../Components/LogoSmall'
import styled from 'styled-components'
import Logo from '../assets/Images/LAE4.svg'

const Container = styled.div`
    padding-left: 0;
    padding-right: 0;
    
    @media (max-width: 600px){
        padding-left: 20px;
        padding-right: 20px;
    }
`

function Category(props) {
    const [categories, setCategories] = useState(undefined);

    useEffect(() => {
        window.scrollTo(0,0)
        getCategoriesFromApi().then((data)=>setCategories(data))
    }, []);


    return(
        <div style={{paddingTop: "6rem"}}>
            <Container>
                <section style={{marginTop: "1rem", marginBottom: "5rem"}}>
                    <div className="container">
                        <Fade bottom>
                            <h1 style={{textAlign: "center"}}>
                                    CATEGORIES
                            </h1>
                        </Fade>
                        <br></br>
                        <div style={{alignItems: "center", justifyContent: "space-evenly", display: "flex", flexWrap: "wrap"}}>
                        {
                            (categories !== undefined)?(
                                <Fade bottom>
                                    <div 
                                        style={{width: "15rem", height: "15rem", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", borderRadius: "25px", marginBottom: "4rem", display: "flex", overflow: "hidden", justifyContent: "center", alignItems: "flexEnd"}}
                                        to={'/results/all'}
                                    >
                                        <div style={{width: "100%", backgroundColor: "rgba(240, 157, 233, 0.467)", display: "flex", alignItems: "center", justifyContent: "center", padding: "0.5rem"}}>
                                            <Link to={'/results/all'} style={{paddingInline: "2rem", textAlign: "center", color: "grey"}}>
                                                <h3>Toutes</h3>
                                            </Link>
                                            <img src={Logo} style={{position:"absolute", height: 60, width: 60, bottom: 20, right: 20}} alt="" />
                                        </div>
                                    </div>
                                    {
                                        categories.map(item=>{
                                            return(
                                                <div
                                                    style={{width: "15rem", height: "15rem", backgroundImage: "url("+item.image+")", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", borderRadius: "25px", marginBottom: "4rem", display: "flex", overflow: "hidden", justifyContent: "center", alignItems: "flexEnd"}}
                                                    to={'/results/'+item.name}
                                                >
                                                    <div style={{width: "100%", backgroundColor: "rgba(240, 157, 233, 0.467)", display: "flex", alignItems: "center", justifyContent: "center", padding: "0.5rem"}}>
                                                        <Link to={'/results/'+item.name} style={{paddingInline: "2rem", textAlign: "center", color:"white"}}>
                                                            <h3>{item.name}</h3>
                                                        </Link>
                                                        <img src={Logo} style={{position:"absolute", height: "auto", width: 60, bottom: 20, right: 20}} alt="" />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Fade>
                            ):(
                                <></>
                            )
                        }
                        </div>
                    </div>
                </section>
            </Container>
        <LogoSmall/>
        </div>
    )
}

export default Category;