import React from "react";
import { Link } from "react-router-dom";
import Logo5 from '../assets/Images/LAE4.svg'
import styled from "styled-components";

const LogoLink = styled(Link)`
    padding: 5px;
    position: absolute;
    top: 1rem;
    left: 1rem;

    @media (min-width: 600px){
        top: 2rem;
        left: 2rem;
    }

    @media (min-width: 900px){
        top: 3rem;
        left: 3rem;
    }
`

const LogoImage = styled.img`
    height: 100px;
    width: auto

    @media (min-width: 600px){
        height: 100px;
        width: auto
    }

    @media (min-width: 900px){
        height: 150px;
        width: auto
    }
`

const Logo = ()=>{
    return(
        <LogoLink 
            to="/"
        >
            <LogoImage
                src={Logo5} 
                alt="L'annuaire anchanté"
            />
        </LogoLink>
    )
}

export default Logo