import Home from './Views/Home'
import Result from './Views/Result'
import ADetail from './Views/ADetail'
import SDetail from './Views/SDetail'
import Category from './Views/Category'
import Article from './Views/Article'
import PDetail from './Views/PDetail'
import Partner from './Views/Partner'
import LogoIm from './assets/Images/LAE4.svg'
import styled from 'styled-components'
import { BrowserRouter, Route, Link, Switch } from "react-router-dom"
import { useState, useEffect } from 'react'
import { getPartnerFromApi } from './Service/API'
import Navbar from './Components/Navbar'

const Container = styled.div`
    padding-left: 0;
    padding-right: 0;
    
    @media (max-width: 600px){
        padding-left: 20px;
        padding-right: 20px;
    }
`

function App() {
    const [partner, setPartner] = useState([]);
    
    useEffect(() => {
        getPartnerFromApi().then((data)=>setPartner(data))
    }, []);

  return (
    <BrowserRouter>
        <Navbar/>
        <Switch>
            <Route exact path="/" component={Home}/>
            <Route path={`/articles`} component={Article}/>
            <Route path={`/article/:id`} component={ADetail}/>
            <Route path={`/category`} component={Category}/>
            <Route path={`/results/:initCategorie`} component={Result}/>
            <Route path={`/result/:id`} component={SDetail}/>
            <Route path={`/partenaire/:id`} component={PDetail}/>
            {/* <Route path={`/about`} component={About}/> */}
            <Route path={`/partners`} component={Partner}/>
        </Switch>
        
        <Container>
            <section>
                <div className="container">
                    <hr></hr>
                    <h1>
                            PARTENAIRES
                    </h1>
                    <br></br>
                    <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
                        {
                            partner.map((item, id) => {
                                if(item.pageId !== undefined && item.pageId !== []){
                                    if(item.link.length > 0){
                                            <a href={item.link}>
                                                <img src={item.logo} style={{height: "150px", width: "auto", marginBottom: 30, borderRadius: 20}} alt={item.name} srcset="" />
                                            </a>
                                    }else{
                                        return(
                                            <Link to={'/partenaire/'+item.pageId}>
                                                <img src={item.logo} style={{height: "150px", width: "auto", marginBottom: 30, borderRadius: 20}} alt={item.name} srcset="" />
                                            </Link>
                                        )
                                    }
                                }else{
                                    if(item.link !== undefined && item.link !== []){
                                        <a href={item.link}>
                                            <img src={item.logo} style={{height: "150px", width: "auto", marginBottom: 30, borderRadius: 20}} alt={item.name} srcset="" />
                                        </a>
                                    }else{
                                        return(
                                            <img src={item.logo} style={{height: "150px", width: "auto", marginBottom: 30, borderRadius: 20}} alt={item.name} srcset="" />
                                        )
                                    }
                                }
                            })
                        }
                    </div>
                </div>
            </section>
        </Container>
        <section style={{marginTop: "5rem", marginBottom: "2rem"}}>
            <div className="" style={{borderTop: "black solid 0px"}}>
                {/* Footer */}
                <footer className="text-center text-lg-start bg-light text-muted" style={{paddingTop: "2rem"}}>
                    {/* Section: Links */}
                    <section>
                        <div className="container text-center text-md-start" style={{marginTop: "2.5rem"}}>
                            {/* Grid row */}
                            <div className="row mt-3" style={{marginTop: "3.5rem"}}>
                                {/* Grid column */}
                                <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4" style={{marginBottom: "4rem"}}>
                                    {/* Content */}
                                    <img
                                        src={LogoIm} 
                                        alt="L'annuaire anchanté"
                                        style={{
                                            height: 200,
                                            width: 200
                                        }}
                                    />
                                    <hr />
                                    <p>
                                        Votre annuaire d'organisation événementielle, unique et rapide.
                                    </p>
                                    <hr />
                                </div>
                                {/* Grid column */}

                                {/* Grid column */}
                                <div className="col-md-3 col-lg-2 col-xl-2 text-center mx-auto mb-4" style={{marginBottom: "4rem"}}>
                                    {/* Links */}
                                    <h4 className="text-uppercase fw-bold mb-4">
                                        Liens utiles
                                    </h4>
                                    <p>
                                        <Link href="#!" className="text-reset">A propos</Link>
                                    </p>
                                    <p>
                                        <Link href="#!" className="text-reset">Insciption</Link>
                                    </p>
                                    <p>
                                        <Link href="#!" className="text-reset">Conditions d'utilisation</Link>
                                    </p>
                                    <p>
                                        <Link href="#!" className="text-reset">Support technique</Link>
                                    </p>
                                </div>
                                {/* Grid column */}

                                {/* Grid column */}
                                <div className="col-md-4 col-lg-3 col-xl-3 text-center mx-auto mb-md-0 mb-4" style={{marginBottom: "4rem"}}>
                                    {/* Links */}
                                    <h4 className="text-uppercase fw-bold mb-4">
                                        Contact
                                    </h4>
                                    <p>
                                    <i className="fas fa-home me-3"></i> 
                                    Ambatolampy<br></br>Antehiroka<br></br>Antananarivo 101
                                    </p>
                                    <p>
                                        <i className="fas fa-envelope me-3"></i>
                                        contact@annuaire-enchante.com
                                    </p>
                                    <p>
                                    <i className="fas fa-phone me-3"></i>
                                    + 261 32 00 000 00
                                    </p>
                                    <p>
                                    <i className="fas fa-phone me-3"></i> 
                                    + 261 33 00 000 00
                                    </p>
                                    <p>
                                    <i className="fas fa-print me-3"></i> 
                                    + 261 34 00 000 00
                                    </p>
                                </div>
                                {/* Grid column */}
                            </div>
                            {/* Grid row */}
                        </div>
                    </section>
                    {/* Section: Links  */}

                    {/* Section: Social media */}
                    <section>
                        <div>
                            <p>Suivez nous sur:</p>
                        </div>
                        <div className="container d-flex justify-content-center pb-4">
                            <Link href="" className="text-reset">
                                Facebook -
                            </Link>
                            <Link href="" className="text-reset">
                                Instagram -
                            </Link>
                            <Link href="" className="text-reset">
                                Linkedin
                            </Link>
                        </div>
                    </section>
                    {/* Section: Social media */}


                    {/* Copyright */}
                    <div className="text-center" style={{backgroundColor: "rgba(0, 0, 0, 0.05)", paddingBlock: "3rem"}}>
                        © 2021 Copyright
                        <br />
                        <Link className="text-reset fw-bold" href="https://annuaire-enchante.com/"> https://annuaire-enchante.com</Link>
                    </div>
                    {/* Copyright */}
                </footer>
                {/* Footer */}
            </div>
        </section>
    </BrowserRouter>  
  );
}

export default App;
