const initialState = {
    cart: [],
    request: []
}

function setting(state = initialState, action) {
    let nextState
    switch (action.type) {
        case 'UPDATE_CART':
            nextState = { ...state, cart: action.value }
            return nextState || state;
        case 'UPDATE_REQUEST':
            nextState = { ...state, request: action.value }
            return nextState || state;
        default:
            return { cart: [], request: [] }
    }
}

export default setting
