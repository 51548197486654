import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getSingleCompanyFromApi } from '../Service/API'
import DOMPurify from 'dompurify'
import Map from '../Components/map'
import LogoSmall from '../Components/LogoSmall'
import styled from 'styled-components'

const Container = styled.div`
    padding-left: 0;
    padding-right: 0;
    
    @media (max-width: 600px){
        padding-left: 20px;
        padding-right: 20px;
    }
`

function PDetail(props) {
    const { id } = useParams()
    const [data, setData] = useState([])
    const [dt, setDt] = useState([])

    useEffect(() => {
        window.scrollTo(0,0)
        getSingleCompanyFromApi(id).then((data)=>{
            setData(data)
            var dt_update = [
                {name: "Secteur d'activité", val: data.secteur},
                {name: "Tarif à partir de", val: data.price},
                {name: "Diplome et/ou certificat", val: data.certification},
                {name: "Prix et/ou Distinction", val: data.award},
                {name: "Adresse", val: data.adresse},
                {name: "Commune", val: data.commune},
                {name: "Province", val: data.province},
                {name: "Tél", val: data.contact},
                {name: "E-Mail", val: data.email},
                {name: "Website", val: data.link},
                {name: "Facebook", val: data.facebook},
                {name: "Instagram", val: data.instagram},
                {name: "Twitter", val: data.twitter}
            ]

            setDt(dt_update)
        })
    }, []);

    return(
        <div style={{paddingTop: "6rem"}}>
            <Container>

                {
                    (data === [] || data === undefined)?(
                        <>
                            <h3>Chargement ...</h3>
                        </>
                    ):(
                        <section>
                            <div className="container">
                                <hr></hr>
                                <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: "center", justifyContent: "center"}}>
                                    <img src={data.logo} style={{height: '150px', width: 'auto', borderRadius: '2rem'}} alt="" srcset="" />
                                    <div style={{paddingLeft: '2rem'}}>
                                        <h1>{data.denomination_sociale}</h1>
                                        <h3>{data.metier} | {data.specialisation}</h3>
                                    </div>
                                </div>
                                <hr></hr>
                                {
                                    (data.slogan)?(
                                        <div>
                                            <h1 style={{
                                                fontWeight: "bold",
                                                textAlign: "center"
                                            }}>
                                                {JSON.parse(data.slogan).h1}
                                            </h1>
                                            <h3 style={{
                                                textAlign: 'center'
                                            }}>
                                                {JSON.parse(data.slogan).h3}
                                            </h3>
                                            <br></br>
                                        </div>
                                    ):(
                                        <></>
                                    )
                                }
                                <div className="d-flex nowrap align-items-center justify-content-between">
                                    <div className="pl-3" style={{columns: '2 300px', columnGap: '2rem'}}>
                                        <p>
                                            {
                                                dt.map((item, index)=>{
                                                        if(item.val !== undefined && item.val !== []){
                                                            return(
                                                                <>
                                                                    <b>
                                                                        {item.name}
                                                                    </b>
                                                                    <br></br>| {item.val}
                                                                    <br></br>
                                                                </>
                                                            )
                                                        }
                                                    })
                                            }
                                        </p>
                                    </div>
                                    <div style={{width: "100%", maxWidth: 400, borderRadius: 20, overflow:"hidden"}}>
                                        {
                                            (data.streetmap)?(
                                                <Map
                                                    location={data.streetmap}
                                                />
                                            ):(
                                                <></>
                                            )
                                        }
                                    </div>
                                </div>
                                <br></br>
                                <div>
                                    <div style={{textAlign: "justify", textIndent: "2rem"}} dangerouslySetInnerHTML={ {__html: DOMPurify.sanitize(data.descriptionHTML)} } />
                                </div>
                                <br></br>
                                <div>
                                    <div style={{columns: "3 150px", columnGap: '1em',}}>
                                        {
                                            (data.image)?(
                                                JSON.parse(data.image).map((item)=>{
                                                    return(
                                                        <img src={"https://apiannu.nessentielle.com/storage/app/public/"+item} alt={item.alt} className="d-clock w-100" style={{paddingBottom: "1em"}} />
                                                    )
                                                })
                                            ):(
                                                <></>
                                            )
                                        }
                                    </div>
                                </div>
                                <br></br>
                            </div>
                        </section>
                    )
                }
            </Container>
            <LogoSmall/>
        </div>
    )
}

export default PDetail;