import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getSingleCompanyFromApi, getAdsFromApi } from '../Service/API'
import Map from '../Components/map'
import LogoSmall from '../Components/LogoSmall'
import styled from 'styled-components'

const Container = styled.div`
    padding-left: 0;
    padding-right: 0;
    
    @media (max-width: 600px){
        padding-left: 20px;
        padding-right: 20px;
    }
`

function SDetail(props) {
    const { id } = useParams()
    const [data, setData] = useState([])
    const [ads, setAds] = useState([])
    const [dt, setDt] = useState([])

    useEffect(() => {
        window.scrollTo(0,0)
        getSingleCompanyFromApi(id).then((data)=>{
            setData(data)
            var dt_update = [
                {name: "Secteur d'activité", val: data.secteur},
                {name: "Tarif à partir de", val: data.price},
                {name: "Diplome et/ou certificat", val: data.certification},
                {name: "Prix et/ou Distinction", val: data.award},
                {name: "Adresse", val: data.adresse},
                {name: "Commune", val: data.commune},
                {name: "Province", val: data.province},
                {name: "Tél", val: data.contact},
                {name: "E-Mail", val: data.email},
                {name: "Website", val: data.link},
                {name: "Facebook", val: data.facebook},
                {name: "Instagram", val: data.instagram},
                {name: "Twitter", val: data.twitter}
            ]

            setDt(dt_update)
        })
        getAdsFromApi().then((data) => {
            if(data !== undefined){
                setAds(data[0])
            }
        })
    }, []);

    return(
        <div style={{paddingTop: "6rem"}}>
            <Container>

                {
                    (data === [] || data === undefined)?(
                        <>
                            <h3>Chargement ...</h3>
                        </>
                    ):(
                        <section>
                            <div className="container">
                                <hr></hr>
                                <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: "center", justifyContent: "center"}}>
                                    <img src={data.logo} style={{height: '150px', width: 'auto', borderRadius: '2rem'}} alt="" srcset="" />
                                    <div style={{paddingLeft: '2rem'}}>
                                        <h1>{data.denomination_sociale}</h1>
                                        <h3>{data.metier} | {data.specialisation}</h3>
                                    </div>
                                </div>
                                <hr></hr>
                                <div className="row">
                                    <div className="col-md-7 col-lg-8 col-12">
                                        {
                                            (data.image)?(
                                                <div id="carousel" className="carousel slide" data-ride="carousel" style={{borderRadius: '2rem', overflow: 'hidden'}}>
                                                    <ol className="carousel-indicators">
                                                        {
                                                            JSON.parse(data.image).map((item, id) => {
                                                                return(
                                                                    <li data-target="#carousel" data-slide-to={id} className={(id===0)?("active"):("")}></li>
                                                                )
                                                            })
                                                        }
                                                    </ol>
                                                    <div className="carousel-inner">
                                                        {
                                                            JSON.parse(data.image).map((item)=>{
                                                                return(
                                                                    <div className="carousel-item active">
                                                                        <img src={"https://apiannu.nessentielle.com/storage/app/public/"+item} alt={item.alt} className="d-clock w-100" />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <a href="#carousel" role="button" data-slide="prev" className="carousel-control-prev">
                                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    </a>
                                                    <a href="#carousel" role="button" data-slide="next" className="carousel-control-next">
                                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    </a>
                                                </div>
                                            ):(
                                                <></>
                                            )
                                        }
                                    </div>
                                    <div className="col-md-1 col-12"></div>
                                    <div className="col-md-4 col-lg-3 col-12 d-block d-sm-none d-md-none" style={{backgroundImage: `url(${ads.image_sm})`, borderRadius:"2rem", height: "100%", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
                                    </div>
                                    <div className="col-12 d-none d-sm-block" style={{backgroundImage: `url(${ads.image_lg})`, borderRadius:"2rem", height: "100%", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
                                    </div>
                                </div>
                                <br></br>
                                <div>
                                    <div className="pl-3 row">
                                        <div className='col-12 col-lg-8'>
                                            <div className='d-fex align-items-center justify-content-center' style={{height: "100%"}}>
                                                <p style={{columns: '2 300px', columnGap: '2rem'}}>
                                                    {
                                                            dt.map((item, index)=>{
                                                                if(item.val !== undefined && item.val !== []){
                                                                    return(
                                                                        <>
                                                                            <b>
                                                                                {item.name}
                                                                            </b>
                                                                            <br></br>| {item.val}
                                                                            <br></br>
                                                                        </>
                                                                    )
                                                                }
                                                            })
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-12 col-lg-4' style={{width: "100%", borderRadius: 20, overflow:"hidden", margin:0, padding:0}}>
                                            {
                                                (data.streetmap)?(
                                                    <Map
                                                        location={data.streetmap}
                                                    />
                                                ):(
                                                    <></>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                <div>
                                    <p style={{textAlign: "justify", textIndent: "2rem"}}>
                                        {data.description}
                                    </p>
                                </div>
                                <br></br>
                            </div>
                        </section>
                    )
                }
            </Container>
            <LogoSmall/>
        </div>
    )
}

export default SDetail;