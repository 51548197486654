import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import Fade from 'react-reveal/Fade'
import Logo from '../Components/Logo'
import { getBannerFromApi, getSBHFromApi } from '../Service/API'
import styled from 'styled-components'

const Container = styled.div`
    padding-left: 0;
    padding-right: 0;
    
    @media (max-width: 600px){
        padding-left: 20px;
        padding-right: 20px;
    }
`

function Home(props) {
    const [banner, setBanner] = useState([])
    const [s_b_home, setS_b_home] = useState([])
    
    useEffect(() => {
        window.scrollTo(0,0)
        getBannerFromApi().then((data)=>setBanner(data[0]))
        getSBHFromApi().then((data)=>setS_b_home(data[0]))
    }, []);

    console.log({sbhome: s_b_home})

    return(
        <>
            <div style={{position: "absolute", display: "flex", justifyContent: "center", height: "170vh", width: "100%", overflow: "hidden", transform: 'translateY(-15rem)', zIndex: -1}}>
                <Fade top>
                    {
                        (banner !== [])?(
                            <div style={{width: "100%",filter: "opacity(1)", backgroundImage: "linear-gradient(to bottom, transparent, white), url("+banner.image+")", backgroundSize:"cover", backgroundRepeat: "no-repeat"}}>
                            </div>
                        ):(
                            <></>
                        )
                    }
                </Fade>
            </div>
            <Container>
                <Fade>
                    <div className="container" style={{height: "calc(100vh)", paddingTop: "8rem"}}>
                        <div className="row" style={{display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between", height: "100%"}}>
                            <div className="col-lg-6 col-md-6 col-12" style={{alignSelf: "end", paddingBottom: "5rem", flexWrap: "nowrap"}}>
                                <h1 className="banner-title" style={{width: "80vw", flexWrap: "nowrap"}}>
                                    {banner.title || "L'annuaire enchanté"}
                                </h1>
                                <h3 className="banner-subt-1">{banner.subtitle || "Votre annuaire d'organisation événementielle."}</h3>
                            </div>
                            <div className="col-lg-1 col-md-1 col-12"></div>
                            <div className="col-lg-5 col-md-5 col-12" style={{alignSelf: "flex-start"}}>
                                <span className='banner-subt-lettrine' style={{float: 'left', fontSize: 100,  lineHeight: "60%", margin:"-.1em 0px"}}>
                                    "
                                </span>
                                <h3 className="banner-subt-2" style={{textAlign: "right", fontWeight: "bold"}}>
                                    {banner.quote || "Quand on aime le jour de son mariage, on s'aime pour la vie"}
                                ".
                                <br />
                                <span style={{fontWeight: "lighter"}}>
                                    {banner.author || "Joseph Lallier"}
                                </span>
                                </h3>
                            </div>
                        </div>
                    </div>
                        <div className="container">
                            <div className="row" style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                {/* <img className="col-12" src={ "dqsdfgs"} style={{height: "100%", width: "100%", borderRadius: 30}} alt="" srcset=""/> */}
                            </div>
                        </div>
                    <br></br>
                    <br></br>
                </Fade>


                {
                    (s_b_home !== [])?(
                        <>
                            <Fade bottom>
                                <section style={{marginTop: "5rem", marginBottom: "5rem"}}>
                                    <div className="d-none d-md-block container">
                                        <div className="row" style={{justifyContent: "space-between", alignItems: "center", display: "flex", flexWrap: "wrap"}}>
                                            <Link to="/category" className="col-lg-5 col-md-5 col-12">
                                                <img src={s_b_home.srvImage} style={{width:"100%", height:"100%"}} alt="" srcset=""/>
                                            </Link>
                                            <br></br>
                                            <div className="col-lg-6 col-md-6 col-12 p-3">
                                                <h1 style={{paddingBottom: "2.5rem"}}>
                                                    <span className='text-uppercase' style={{borderBottom: "#c89ba2 solid 5px"}}>catégories</span>
                                                </h1>
                                                <h3 style={{textAlign: "left"}}>
                                                    {s_b_home.srvSummary}
                                                </h3>
                                                <div style={{display: "flex", justifyContent: "flex-end", marginTop: "5rem"}}>
                                                    <Link to="/category" className="btn btn-lg" style={{borderRadius: "1.5rem", padding: "1rem", paddingInline: "3rem", backgroundColor: "#c89ba2", color: "white"}}>
                                                        TOUT AFFICHER
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-block d-md-none container">
                                        <div style={{justifyContent: "space-between", alignItems: "center", display: "flex", flexWrap: "wrap"}}>
                                            <div>
                                                <h1 style={{paddingBottom: "2.5rem"}}>
                                                    <span className='text-uppercase' style={{borderBottom: "#c89ba2 solid 5px"}}>catégories</span>
                                                </h1>
                                                <Link to="/category">
                                                    <img src={s_b_home.srvImage} style={{width:"100%", height:"100%"}} alt="" srcset=""/>
                                                </Link>
                                                <hr></hr>
                                                <h3 style={{textAlign: "left"}}>
                                                    {s_b_home.srvSummary}
                                                </h3>
                                                <div style={{display: "flex", justifyContent: "flex-end", marginTop: "1rem"}}>
                                                    <Link to="/category" className="btn btn-lg" style={{borderRadius: "1.5rem", padding: "1rem", backgroundColor: "#c89ba2", color: "white"}}>
                                                        TOUT AFFICHER
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </Fade>
                            <Fade bottom>
                                <section style={{marginTop: "5rem", marginBottom: "5rem"}}>
                                    <div className="d-none d-md-block container">
                                        <div className="row" style={{justifyContent: "space-between", alignItems: "center", display: "flex", flexWrap: "wrap"}}>
                                            <div className="col-lg-6 col-md-6 col-12 p-3">
                                                <h1 style={{textAlign: "right", paddingBottom: "2.5rem"}}>
                                                    <span style={{borderBottom: "#c89ba2 solid 5px"}}>BLOG</span>
                                                </h1>
                                                <h3 style={{textAlign: "right"}}>
                                                    {s_b_home.artSummary}
                                                </h3>
                                                <div style={{display: "flex", justifyContent: "flex-start", marginTop: "5rem"}}>
                                                    <Link to="/articles" className="btn btn-lg" style={{borderRadius: "1.5rem", padding: "1rem", paddingInline: "3rem", backgroundColor: "#c89ba2", color: "white"}}>
                                                        TOUT AFFICHER
                                                    </Link>
                                                </div>
                                            </div>
                                            <br></br>
                                            <Link to="/articles" className="col-lg-5 col-md-5 col-12">
                                                <img src={s_b_home.artImage} style={{width:"100%", height:"100%"}} alt="" srcset=""/>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="d-block d-md-none container">
                                        <div style={{justifyContent: "space-between", alignItems: "center", display: "flex", flexWrap: "wrap"}}>
                                            <div>
                                                <h1 style={{textAlign: "left", paddingBottom: "2.5rem"}}>
                                                    <span style={{borderBottom: "#c89ba2 solid 5px"}}>BLOG</span>
                                                </h1>
                                                <Link to="/articles">
                                                    <img src={s_b_home.artImage} style={{width:"100%", height:"100%"}} alt="" srcset=""/>
                                                </Link>
                                                <hr></hr>
                                                <h3 style={{textAlign: "left"}}>
                                                    {s_b_home.artSummary}
                                                </h3>
                                                <div style={{display: "flex", justifyContent: "flex-end", marginTop: "1rem"}}>
                                                    <Link to="/articles" className="btn btn-lg" style={{borderRadius: "1.5rem", padding: "1rem", backgroundColor: "#c89ba2", color: "white"}}>
                                                        TOUT AFFICHER
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </Fade>
                        </>
                    ):(
                        <></>
                    )
                }
            <Logo/>
            </Container>
        </>
    )
}

export default Home;